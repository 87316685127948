import ms from 'ms';
import React, { useEffect, useState } from 'react';
import { useInterval } from '@mantine/hooks';
import { Button, Text, Dialog } from '@mantine/core';
import { IS_PRODUCTION } from '../constants';

const NewVersionDeployed = () => {
  const [opened, setOpened] = useState(false);

  const interval = useInterval(async () => {
    if (IS_PRODUCTION) {
      const response = await fetch('/api/git-sha');
      const data = await response.json();

      if (data.version !== process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA) {
        interval.stop();
        setOpened(true);
      }
    }
  }, ms('2mins'));

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  return (
    <Dialog opened={opened} size="lg" radius="md">
      <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
        A new version of the app has been deployed
      </Text>

      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload
      </Button>
    </Dialog>
  );
};

export default NewVersionDeployed;
