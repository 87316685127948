import { SpotlightAction, SpotlightActionProps, SpotlightProvider } from '@mantine/spotlight';
import React, { ReactNode, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import {
  Group,
  Loader,
  createStyles,
  rem,
  Text,
  ActionIcon,
  Stack,
  CopyButton,
} from '@mantine/core';
import { isEmpty } from 'lodash';
import {
  useAskShepsyMutation,
  useUpdateFeedbackShepsyMutation,
  useUserHistoryShepsyQuery,
} from '../graphql/operations/ShepsyAI.generated';
import { HandThumbsUp } from '@emotion-icons/bootstrap/HandThumbsUp';
import { HandThumbsUpFill } from '@emotion-icons/bootstrap/HandThumbsUpFill';
import { HandThumbsDown } from '@emotion-icons/bootstrap/HandThumbsDown';
import { HandThumbsDownFill } from '@emotion-icons/bootstrap/HandThumbsDownFill';
import { Checkmark } from '@emotion-icons/evaicons-solid/Checkmark';
import { Copy } from '@emotion-icons/boxicons-regular/Copy';
import { useSession } from '../hooks/useSession';
const useStyles = createStyles((theme) => ({
  action: {
    fontSize: '16px',
    fontWeight: 500,
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: `${rem(10)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
    }),
    // this is right way of specifting
    '&[data-hovered]': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
    },
  },
}));

const ShepsyAction = ({ action }: SpotlightActionProps) => {
  const [updateFeedback] = useUpdateFeedbackShepsyMutation();
  const { classes } = useStyles();

  return (
    <Stack className={classes.action}>
      <Group noWrap>
        <div style={{ flex: 1 }}>
          <Text>{action.title}</Text>
          <Text color="dimmed" style={{ fontSize: '14px', fontWeight: 300, color: 'black' }}>
            {action.description}
          </Text>
        </div>
        <Group spacing={0}>
          <ActionIcon
            variant="subtle"
            onClick={() =>
              updateFeedback({
                variables: {
                  input: {
                    id: action.id as string,
                    feedback: action.feedback === null || action.feedback === false ? true : null,
                  },
                },
              })
            }
          >
            {action.feedback === true ? <HandThumbsUpFill size={14} /> : null}
            {action.feedback === null || action.feedback === false ? (
              <HandThumbsUp size={14} />
            ) : null}
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            onClick={() =>
              updateFeedback({
                variables: {
                  input: {
                    id: action.id as string,
                    feedback: action.feedback === null || action.feedback === true ? false : null,
                  },
                },
              })
            }
          >
            {action.feedback === false ? <HandThumbsDownFill size={14} /> : null}
            {action.feedback === null || action.feedback === true ? (
              <HandThumbsDown size={14} />
            ) : null}
          </ActionIcon>
          <CopyButton value={action.description as string} timeout={2000}>
            {({ copied, copy }) => (
              <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                {copied ? <Checkmark size="1rem" /> : <Copy size="1rem" />}
              </ActionIcon>
            )}
          </CopyButton>
        </Group>
      </Group>
    </Stack>
  );
};

const ShepsySpotlight = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState([] as SpotlightAction[]);
  const [query, setCurrentQuery] = useState('');
  const { isAdmin } = useSession();

  const { refetch } = useUserHistoryShepsyQuery({
    skip: !isAdmin,
    onCompleted: (resp) => {
      const actions = resp.userHistoryShepsy.map((item) => ({
        id: item.id,
        title: item.prompt,
        description: item.response as string,
        feedback: item.feedback,
        onTrigger: () => null,
      }));
      setData(actions);
    },
  });
  const [askShepsy, { loading: shepsyThinking }] = useAskShepsyMutation({
    onCompleted: async () => {
      await refetch();
      setCurrentQuery('');
    },
    onError: (err: any) => showNotification({ message: `Error: ${err}`, color: 'red' }),
  });

  return (
    <SpotlightProvider
      disabled={!isAdmin}
      searchPlaceholder="Ask ShepsyAI"
      actions={data}
      shortcut="/"
      searchIcon={shepsyThinking ? <Loader size="xs" /> : null}
      onQueryChange={(q) => setCurrentQuery(q)}
      cleanQueryOnClose
      closeOnActionTrigger={false}
      highlightQuery
      query={query}
      actionComponent={ShepsyAction}
      onKeyDown={async (event) => {
        if (event.key === 'Enter') {
          if (isEmpty(query)) return;
          await askShepsy({ variables: { input: { prompt: query } } });
        }
      }}
    >
      {children}
    </SpotlightProvider>
  );
};
export default ShepsySpotlight;
