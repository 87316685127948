import { SEND_TO_ANALYTICS } from '../constants';
import { PolicyProduct, ExposureType } from '../graphql/types.generated';

export enum StepNameEnum {
  NewSubmission = 'new_submission_modal',
  RiskTierGeneralLiability = 'risk_tier_general_liability',
  RiskTierAutoLiability = 'risk_tier_auto_liability',
  ManualRatingsGeneralLiability = 'manual_ratings_general_liability',
  ManualRatingsAutoLiability = 'manual_ratings_auto_liability',
  ManualRatingsFleetDetails = 'manual_ratings_fleet_details',
  Pricing = 'final_pricing',
}

export enum EventTypeEnum {
  TimedUserAction = 'timed_user_action_test',
  DigitalQuoteViewed = 'digital_quote_viewed',
}

export type TimeTrackingEventProperties = {
  quote_id?: string;
  policy_id?: string;
  policy_product?: PolicyProduct;
  exposure_type?: ExposureType;
  step_name: StepNameEnum;
  active_time: number;
  idle_time: number;
};

type SegmentEventProperties = TimeTrackingEventProperties | object;

export const segmentTrack = (eventName: EventTypeEnum, params: SegmentEventProperties) => {
  if (SEND_TO_ANALYTICS !== 'true') {
    return null;
  }
  global.window.analytics.track(eventName, params);
};
export const segmentIdentify = (userId: string, params: object) => {
  if (SEND_TO_ANALYTICS !== 'true') {
    return null;
  }
  global.window.analytics.identify(userId, params);
};
export const IDLE_TIMEOUT = 5_000; // in ms, when to start counting timeout
